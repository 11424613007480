import SvgArrowUp from '@sats-group/icons/24/arrow-up';
import SvgEdit from '@sats-group/icons/24/edit';
import cn from 'classnames';
import React, { useState, useEffect, useRef } from 'react';
import Collapse from 'react-tiny-collapse';

import LinkButton from 'sats-ui-lib/react/link-button';
import Text from 'sats-ui-lib/react/text';

import { CheckoutSummary as Props } from './checkout-summary.props';

const CheckoutSummary: React.FunctionComponent<Props> = ({
  activeCampaign,
  buttonLabel,
  changeMembership,
  description,
  link,
  priceItems,
  selectedItems,
  sponsorshipType,
  standardPrice,
  title,
  totalPrice,
}) => {
  const [isOpen, toggleIsOpen] = useState(false);
  const summaryRef = useRef<HTMLDivElement>(null);

  const checkoutSummary = cn('checkout-summary', {
    'checkout-summary--opened': isOpen,
  });

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        summaryRef.current &&
        !summaryRef.current.contains(event.target as Node)
      ) {
        toggleIsOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className={checkoutSummary} ref={summaryRef}>
        <Collapse isOpen={isOpen} className="checkout-summary__collapse">
          <div>
            <div className="checkout-summary__header">
              <div className="checkout-summary__membership">
                <Text theme={Text.themes.emphasis}>{title}</Text>
                <Text size={Text.sizes.small}>{description}</Text>
              </div>
              {changeMembership ? (
                <div className="checkout-summary__link">
                  <a
                    href={changeMembership.href}
                    aria-label={changeMembership.label}
                    title={changeMembership.label}
                  >
                    <SvgEdit />
                  </a>
                </div>
              ) : null}
            </div>

            <div className="checkout-summary__body">
              {activeCampaign ? (
                <div className="checkout-summary__section">
                  <div className="checkout-summary__line-item">
                    <Text theme={Text.themes.emphasis}>
                      {activeCampaign.title}
                    </Text>
                    <Text>{activeCampaign.value}</Text>
                  </div>
                </div>
              ) : null}

              <div className="checkout-summary__section">
                {selectedItems.map(({ title, value, href, label }) => (
                  <div
                    key={title}
                    className={cn('checkout-summary__line-item', {
                      'checkout-summary__line-item--editable': href && label,
                    })}
                  >
                    <Text theme={Text.themes.emphasis}>{title}</Text>
                    <div className="checkout-summary__line-item-value">
                      <Text>{value}</Text>
                      {href && label ? (
                        <a href={href} aria-label={label} title={label}>
                          <SvgEdit />
                        </a>
                      ) : null}
                    </div>
                  </div>
                ))}
              </div>

              <div className="checkout-summary__section">
                {priceItems.map(({ title, value }) => (
                  <div key={title} className="checkout-summary__line-item">
                    <Text theme={Text.themes.emphasis}>{title}</Text>
                    <Text>{value}</Text>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Collapse>

        <div className="checkout-summary__footer">
          <div className="checkout-summary__mobile-toggle">
            <button
              aria-label={isOpen ? buttonLabel.close : buttonLabel.open}
              aria-expanded={isOpen}
              className="checkout-summary__button"
              onClick={event => {
                event.stopPropagation();
                toggleIsOpen(!isOpen);
              }}
            >
              <div
                className={cn('checkout-summary__toggle-arrow', {
                  'checkout-summary__toggle-arrow--flipped': isOpen,
                })}
              >
                <SvgArrowUp />
              </div>
              <div className="checkout-summary__price-mobile-wrapper">
                <div className="checkout-summary__total-price-mobile">
                  <Text>{totalPrice.title}</Text>
                  <Text theme={Text.themes.emphasis}>{totalPrice.value}</Text>
                </div>
                {standardPrice ? (
                  <Text size={Text.sizes.small}>{standardPrice}</Text>
                ) : null}
              </div>
            </button>
            {link ? (
              <div className="checkout-summary__next">
                <LinkButton
                  variant={'complete'}
                  {...link}
                  data-test-next-step
                />
              </div>
            ) : null}
          </div>

          <div className="checkout-summary__price-desktop">
            <Text size={Text.sizes.small}>{totalPrice.title}</Text>
          </div>
          <div className="checkout-summary__price-desktop">
            <div className="checkout-summary__total-price">
              <Text
                className="checkout-summary__first-price"
                size={Text.sizes.headline2}
                theme={Text.themes.emphasis}
              >
                {totalPrice.value}
              </Text>
              {standardPrice ? (
                <Text size={Text.sizes.small}>{standardPrice}</Text>
              ) : null}
            </div>
          </div>
        </div>
        {sponsorshipType ? (
          <div className="checkout-summary__sponsorship-type">
            <Text>{sponsorshipType}</Text>
          </div>
        ) : null}
      </div>
      {isOpen ? <div className="checkout-summary__backdrop"></div> : null}
    </>
  );
};

export default CheckoutSummary;
