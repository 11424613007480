import React from 'react';

import Disclaimer from 'shared-ui/components/disclaimer/disclaimer';
import Layout from 'shared-ui/components/layout/layout';
import type { NamedFC } from 'shared-ui/named-fc.types';

import { ChooseMembership as Props } from './choose-membership.props';
import MembershipCard from './components/membership-card/membership-card';

const ChooseMembership: NamedFC<Props> = ({
  disclaimer,
  layout,
  memberships,
}) => (
  <Layout {...layout}>
    <div className="choose-membership">
      <div className="choose-membership__memberships">
        {memberships.map(membership => (
          <MembershipCard key={membership.id} {...membership} />
        ))}
      </div>
      {disclaimer ? <Disclaimer {...disclaimer} /> : null}
    </div>
  </Layout>
);

ChooseMembership.displayName = 'ChooseMembership';

export default ChooseMembership;
