import React, { useRef, useState } from 'react';

import Text from 'sats-ui-lib/react/text';

import SplitLayout from 'shared-ui/components/split-layout/split-layout';
import type { NamedFC } from 'shared-ui/named-fc.types';

import getAdyenLocale from 'functions/payment/utils/get-adyen-locale';
import useAsyncEffect from 'functions/poc-payment/hooks/use-async-effect';

import { useAdyen, type Core } from './hooks/use-adyen';
import { PocPayment as Props } from './poc-payment.props';

const PocPayment: NamedFC<Props> = ({
  adyenClientKey,
  adyenEnvironment,
  adyenTitle,
  consent,
  description,
  language,
  layout,
  messages = [],
  onSuccessUrl,
  paymentErrorMessage,
  paymentModuleLoadingErrorMessage,
  paymentRefusedMessage,
  paymentSummary,
  session,
}) => {
  const [paymentMessages, setPaymentMessages] = useState(messages);
  const checkoutRef = useRef<Core>();

  const [, hasAdyenLoaded] = useAdyen(() =>
    setPaymentMessages([
      ...paymentMessages,
      {
        text: paymentModuleLoadingErrorMessage,
        theme: 'error',
      },
    ])
  );

  useAsyncEffect(async () => {
    if (!hasAdyenLoaded || !window.AdyenCheckout || !onSuccessUrl) {
      return;
    }

    if (!session) {
      return;
    }

    const adyenConfiguration: Parameters<typeof window.AdyenCheckout>[0] = {
      environment: adyenEnvironment, // Change to 'live' for the live environment.
      clientKey: adyenClientKey, // Public key used for client-side authentication: https://docs.adyen.com/development-resources/client-side-authentication
      locale: getAdyenLocale(language),
      session: session,
      onPaymentCompleted: response => {
        switch (response.resultCode) {
          case 'Authorised': {
            window.location.assign(onSuccessUrl);
            return;
          }
          case 'Refused': {
            setPaymentMessages([
              {
                text: paymentRefusedMessage,
                theme: 'error',
              },
            ]);

            if (checkoutRef.current) {
              checkoutRef.current.update();
            }

            break;
          }
          case 'Error':
          default: {
            setPaymentMessages([
              {
                text: paymentErrorMessage,
                theme: 'error',
              },
            ]);

            if (checkoutRef.current) {
              checkoutRef.current.update();
            }
          }
        }
      },
      onError: () => {
        setPaymentMessages([
          {
            text: paymentErrorMessage,
            theme: 'error',
          },
        ]);

        if (checkoutRef.current) {
          checkoutRef.current.update();
        }
      },
      // Any payment method specific configuration. Find the configuration specific to each payment method:  https://docs.adyen.com/payment-methods
      // For example, this is 3D Secure configuration for cards:
      paymentMethodsConfiguration: {
        card: {
          name: adyenTitle,
          hasHolderName: true,
          holderNameRequired: true,
        },
      },
    };

    const checkout = await window.AdyenCheckout(adyenConfiguration);
    checkout.create('dropin').mount(`#dropin-container`);
    checkoutRef.current = checkout;
    setPaymentMessages([]);
  }, [hasAdyenLoaded]);

  return (
    <SplitLayout messages={paymentMessages} {...layout}>
      <div className="payment">
        <div className="payment__disclaimer">
          <Text size={Text.sizes.interface}>{description}</Text>
          {paymentSummary ? (
            <div className="payment__summary">
              {paymentSummary.map(({ label, value }) => (
                <div key={label} className="payment__summary-line">
                  <Text size={Text.sizes.interface}>{label}</Text>
                  <Text size={Text.sizes.interface}>{value}</Text>
                </div>
              ))}
            </div>
          ) : null}
        </div>
        <div id="dropin-container"></div>
        <Text className="payment__disclaimer" size={Text.sizes.interface}>
          {consent}
        </Text>
      </div>
    </SplitLayout>
  );
};

PocPayment.displayName = 'PocPayment';

export default PocPayment;
