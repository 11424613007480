import React from 'react';

import Text from 'sats-ui-lib/react/text';

import { CmapaignBanner as Props } from './campaign-banner.props';

const CampaignBanner: React.FC<Props> = ({ description, label, title }) => {
  return (
    <div className="campaign-banner">
      <div className="campaign-banner__track">
        <Text theme={Text.themes.emphasis} size={Text.sizes.small}>
          {label}
        </Text>
        <Text size={Text.sizes.small}>&bull;</Text>
        <Text size={Text.sizes.small}>{title}</Text>
        {description ? (
          <>
            <Text size={Text.sizes.small}>&bull;</Text>
            <Text size={Text.sizes.small}>{description}</Text>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default CampaignBanner;
