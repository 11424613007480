import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import cn from 'classnames';
import React from 'react';

import { ThemedComponent } from 'shared-ui/types/types';

import { renderOptions } from './rich-text-renderer-options';
import type { RichText as Props } from './rich-text.types';
import { themes } from './rich-text.types';

const RichText: ThemedComponent<Props, typeof themes> = ({
  content,
  theme,
}) => (
  <div className={cn('rich-text', { 'rich-text--small-paragraphs': theme })}>
    {content ? documentToReactComponents(content, renderOptions) : null}
  </div>
);

RichText.themes = themes;

export default RichText;
