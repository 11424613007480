import React from 'react';

import Button from 'sats-ui-lib/react/button';
import Checkbox from 'sats-ui-lib/react/checkbox';
import LinkButton from 'sats-ui-lib/react/link-button';
import Text from 'sats-ui-lib/react/text';

import SplitLayout from 'shared-ui/components/split-layout/split-layout';
import type { NamedFC } from 'shared-ui/named-fc.types';

import OptionLinks from 'functions/customize-membership/components/option-links/option-links';

import { CustomizeMembership as Props } from './customize-membership.props';

const CustomizeMembership: NamedFC<Props> = ({
  addons,
  layout,
  nextUrl,
  options = [],
  nextLabel,
}) => (
  <SplitLayout {...layout}>
    <div className="customize-membership">
      <div className="customize-membership__options">
        {options.map(option => (
          <div
            className="customize-membership__option-category"
            key={option.title}
          >
            <Text
              elementName="h2"
              size={Text.sizes.large}
              theme={Text.themes.emphasis}
            >
              {option.title}
            </Text>
            <OptionLinks {...option.options} />
          </div>
        ))}

        {addons ? (
          <div className="customize-membership__option-category">
            <Text size={Text.sizes.large} theme={Text.themes.emphasis}>
              {addons.title}
            </Text>
            {addons.options.map(({ link, option }) => (
              <div className="customize-membership__addon" key={link}>
                <Checkbox
                  theme={Checkbox.themes.blue}
                  {...option}
                  onClick={() => window.location.assign(link)}
                />
              </div>
            ))}
          </div>
        ) : null}
      </div>
      {nextUrl ? (
        <div className="customize-membership__submit">
          <LinkButton
            size={Button.sizes.large}
            type="submit"
            text={nextLabel}
            href={nextUrl}
            data-test-next-step
          />
        </div>
      ) : null}
    </div>
  </SplitLayout>
);

CustomizeMembership.displayName = 'CustomizeMembership';

export default CustomizeMembership;
