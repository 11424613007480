import Check from '@sats-group/icons/16/check';
import React from 'react';

import LinkButton from 'sats-ui-lib/react/link-button';
import SvgElixia from 'sats-ui-lib/react/logos/elixia-small';
import Sats from 'sats-ui-lib/react/logos/sats-small';
import Text from 'sats-ui-lib/react/text';

import RichText from 'shared-ui/components/rich-text/rich-text';
import type { NamedFC } from 'shared-ui/named-fc.types';

import { FreeMembershipLanding as Props } from './free-membership-landing.props';

const FreeMembershipLanding: NamedFC<Props> = ({
  description,
  disclaimer,
  language,
  link,
  title,
  uniqueSellingPoints,
}) => {
  return (
    <div className="free-membership-landing">
      <div className="free-membership-landing__logo">
        {language === 'fi' ? <SvgElixia /> : <Sats />}
      </div>
      <div className="free-membership-landing__content">
        <div className="free-membership-landing__content-section-top">
          <Text
            elementName="h1"
            size={Text.sizes.headline1}
            theme={Text.themes.headline}
            italic
          >
            {title}
          </Text>
          {description ? <RichText {...description} /> : null}
          {uniqueSellingPoints.length ? (
            <ul className="free-membership-landing__usp-list">
              {uniqueSellingPoints.map(usp => (
                <li className="free-membership-landing__usp" key={usp}>
                  <Check />
                  <Text>{usp}</Text>
                </li>
              ))}
            </ul>
          ) : null}
        </div>
        <div className="free-membership-landing__content-section-bottom">
          <div>
            <LinkButton variant={LinkButton.variants.cta} {...link} />
          </div>
          <div className="free-membership-landing__disclaimer">
            <RichText
              theme={RichText.themes['small-paragraphs']}
              {...disclaimer}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

FreeMembershipLanding.displayName = 'FreeMembershipLanding';

export default FreeMembershipLanding;
