import React from 'react';

import Disclaimer from 'shared-ui/components/disclaimer/disclaimer';
import Layout from 'shared-ui/components/layout/layout';
import type { NamedFC } from 'shared-ui/named-fc.types';

import { ChooseMemberType as Props } from './choose-member-type.props';
import MemberTypeCard from './components/member-type-card/member-type-card';

const ChooseMemberType: NamedFC<Props> = ({
  layout,
  memberTypes,
  disclaimer,
}) => (
  <Layout {...layout}>
    <div className="choose-member-type">
      <div className="choose-member-type__member-types">
        {memberTypes
          ? memberTypes.map(memberType => (
              <MemberTypeCard key={memberType.id} {...memberType} />
            ))
          : null}
      </div>
      {disclaimer ? <Disclaimer {...disclaimer} /> : null}
    </div>
  </Layout>
);

ChooseMemberType.displayName = 'ChooseMemberType';

export default ChooseMemberType;
