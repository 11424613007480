import React, { useState } from 'react';

import Button from 'sats-ui-lib/react/button';
import Checkbox from 'sats-ui-lib/react/checkbox';
import HiddenInput from 'sats-ui-lib/react/hidden-input';
import SvgElixia from 'sats-ui-lib/react/logos/elixia-small';
import Sats from 'sats-ui-lib/react/logos/sats-small';
import Modal from 'sats-ui-lib/react/modal';
import Select from 'sats-ui-lib/react/select';
import Text from 'sats-ui-lib/react/text';

import ContentContainer from 'shared-ui/components/content-container/content-container';
import FormInputs from 'shared-ui/components/form-inputs/form-inputs';
import RichText from 'shared-ui/components/rich-text/rich-text';
import type { NamedFC } from 'shared-ui/named-fc.types';

import { fillRegistrationForm } from 'functions/register/utils/registration-form-filler';

import { PocRegister as Props } from './poc-register.props';

const PocRegister: NamedFC<Props> = ({
  endpoint,
  generalTerms,
  hiddenInputs = [],
  isDebugMode,
  language,
  marketingConsent,
  memberForm,
  startDate,
  submit,
}) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isTermsModalOpen, setTermsModalOpen] = useState<boolean>(false);
  const [isMarketingConsentModalOpen, setMarketingConsentModalOpen] =
    useState<boolean>(false);

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = event => {
    if (isSubmitting || !(event.target as HTMLFormElement).checkValidity()) {
      event.preventDefault();
      return;
    }
    setIsSubmitting(true);
  };

  return (
    <div className="poc-register">
      <div className="poc-register__logo">
        <ContentContainer
          className="poc-choose-membership__content"
          theme={ContentContainer.themes.wide}
        >
          {language === 'fi' ? <SvgElixia /> : <Sats />}
        </ContentContainer>
      </div>
      <ContentContainer
        className="poc-register__form-wrapper"
        theme={ContentContainer.themes.narrow}
      >
        <Text theme={Text.themes.headline} size={Text.sizes.headline3}>
          Personlige opplysninger
        </Text>
        <form
          action={endpoint}
          method="POST"
          className="poc-register__form"
          onSubmit={handleSubmit}
        >
          {hiddenInputs.map(hiddenInput => (
            <HiddenInput key={hiddenInput.name} {...hiddenInput} />
          ))}
          {isDebugMode ? (
            <div className="poc-register__populate-fields-btn">
              <Button
                text="Populate fields"
                onClick={() => fillRegistrationForm('no')}
                variant={Button.variants.secondaryWhite}
              />
            </div>
          ) : null}
          <Select name="startDate" {...startDate} />
          <div>{memberForm ? <FormInputs {...memberForm} /> : null}</div>
          {generalTerms && generalTerms.terms ? (
            <div className="poc-register__terms">
              <Checkbox
                theme={Checkbox.themes.light}
                {...generalTerms.checkbox}
              />
              <div>
                <Button
                  {...generalTerms.button}
                  variant={Button.variants.secondaryWhite}
                  size={Button.sizes.small}
                  onClick={() => setTermsModalOpen(true)}
                />
              </div>
              {isTermsModalOpen ? (
                <Modal
                  {...generalTerms.modal}
                  onClose={() => setTermsModalOpen(false)}
                >
                  <RichText {...generalTerms.terms} />
                </Modal>
              ) : null}
            </div>
          ) : null}
          {marketingConsent && marketingConsent.terms ? (
            <div className="poc-register__terms">
              <Checkbox
                theme={Checkbox.themes.light}
                {...marketingConsent.checkbox}
              />
              <div>
                <Button
                  {...marketingConsent.button}
                  variant={Button.variants.secondaryWhite}
                  size={Button.sizes.small}
                  onClick={() => setMarketingConsentModalOpen(true)}
                />
              </div>
              {isMarketingConsentModalOpen ? (
                <Modal
                  {...marketingConsent.modal}
                  onClose={() => setMarketingConsentModalOpen(false)}
                >
                  <RichText {...marketingConsent.terms} />
                </Modal>
              ) : null}
            </div>
          ) : null}
          <Button
            className="poc-register__submit"
            size={Button.sizes.large}
            variant={Button.variants.cta}
            type="submit"
            text={submit}
            disabled={isSubmitting ? true : undefined}
            theme={isSubmitting ? 'spinner' : undefined}
          />
        </form>
      </ContentContainer>
    </div>
  );
};

PocRegister.displayName = 'PocRegister';

export default PocRegister;
