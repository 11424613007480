import React, { useState } from 'react';

import Button from 'sats-ui-lib/react/button';
import Checkbox from 'sats-ui-lib/react/checkbox';
import HiddenInput from 'sats-ui-lib/react/hidden-input';
import Modal from 'sats-ui-lib/react/modal';
import Select from 'sats-ui-lib/react/select';

import FormInputs from 'shared-ui/components/form-inputs/form-inputs';
import RichText from 'shared-ui/components/rich-text/rich-text';
import SplitLayout from 'shared-ui/components/split-layout/split-layout';
import type { NamedFC } from 'shared-ui/named-fc.types';

import { fillRegistrationForm } from 'functions/register/utils/registration-form-filler';

import { FreeMembershipRegister as Props } from './free-membership-register.props';

const FreeMembershipRegister: NamedFC<Props> = ({
  endpoint,
  generalTerms,
  hiddenInputs = [],
  isDebugMode,
  language,
  layout,
  marketingConsent,
  memberForm,
  startDate,
  submit,
}) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isTermsModalOpen, setTermsModalOpen] = useState<boolean>(false);
  const [isMarketingConsentModalOpen, setMarketingConsentModalOpen] =
    useState<boolean>(false);

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = event => {
    if (isSubmitting || !(event.target as HTMLFormElement).checkValidity()) {
      event.preventDefault();
      return;
    }
    setIsSubmitting(true);
  };

  return (
    <SplitLayout {...layout}>
      <div className="free-membership-register">
        <form
          action={endpoint}
          method="POST"
          className="free-membership-register__form"
          onSubmit={handleSubmit}
        >
          {hiddenInputs.map(hiddenInput => (
            <HiddenInput key={hiddenInput.name} {...hiddenInput} />
          ))}
          {isDebugMode ? (
            <div className="free-membership-register__populate-fields-btn">
              <Button
                text="Populate fields"
                onClick={() => fillRegistrationForm(language)}
              />
            </div>
          ) : null}

          <Select name="startDate" {...startDate} />
          <div className="free-membership-register__form-inputs-container">
            {memberForm ? <FormInputs {...memberForm} /> : null}
          </div>
          {generalTerms && generalTerms.terms ? (
            <div className="free-membership-register__terms">
              <Checkbox
                theme={Checkbox.themes.blue}
                {...generalTerms.checkbox}
              />
              <div>
                <Button
                  {...generalTerms.button}
                  variant={Button.variants.tertiary}
                  size={Button.sizes.small}
                  onClick={() => setTermsModalOpen(true)}
                />
              </div>
              {isTermsModalOpen ? (
                <Modal
                  {...generalTerms.modal}
                  onClose={() => setTermsModalOpen(false)}
                >
                  <RichText {...generalTerms.terms} />
                </Modal>
              ) : null}
            </div>
          ) : null}
          {marketingConsent && marketingConsent.terms ? (
            <div className="free-membership-register__terms">
              <Checkbox
                theme={Checkbox.themes.blue}
                {...marketingConsent.checkbox}
              />
              <div>
                <Button
                  {...marketingConsent.button}
                  variant={Button.variants.tertiary}
                  size={Button.sizes.small}
                  onClick={() => setMarketingConsentModalOpen(true)}
                />
              </div>
              {isMarketingConsentModalOpen ? (
                <Modal
                  {...marketingConsent.modal}
                  onClose={() => setMarketingConsentModalOpen(false)}
                >
                  <RichText {...marketingConsent.terms} />
                </Modal>
              ) : null}
            </div>
          ) : null}
          <div className="free-membership-register__submit">
            <Button
              size={Button.sizes.large}
              type="submit"
              text={submit}
              disabled={isSubmitting ? true : undefined}
              theme={isSubmitting ? 'spinner' : undefined}
            />
          </div>
        </form>
      </div>
    </SplitLayout>
  );
};

FreeMembershipRegister.displayName = 'FreeMembershipRegister';

export default FreeMembershipRegister;
