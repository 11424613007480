import { createElement } from 'react';
import { hydrateRoot } from 'react-dom/client';

import { pages } from './page-map.generated';

const hydrate = () => {
  const mountNode = document.querySelector(`[data-mount]`);
  if (!mountNode) {
    return;
  }

  const componentName = mountNode.getAttribute('data-mount');
  if (!componentName) {
    return;
  }

  const component = pages[componentName];
  if (!component) {
    return;
  }

  const propsNode = document.querySelector('[data-props]');
  if (!propsNode) {
    return;
  }

  const props = propsNode.textContent;
  if (!props) {
    return;
  }

  hydrateRoot(mountNode, createElement(component, JSON.parse(props)));
  document.documentElement.setAttribute('data-hydrated', `${Date.now()}`);
};

hydrate();
