import cn from 'classnames';
import React, { useState } from 'react';

import Button from 'sats-ui-lib/react/button';
import Checkbox from 'sats-ui-lib/react/checkbox';
import HiddenInput from 'sats-ui-lib/react/hidden-input';
import LinkButton from 'sats-ui-lib/react/link-button';
import Modal from 'sats-ui-lib/react/modal';
import Select from 'sats-ui-lib/react/select';
import Text from 'sats-ui-lib/react/text';

import FormInputs from 'shared-ui/components/form-inputs/form-inputs';
import RichText from 'shared-ui/components/rich-text/rich-text';
import SplitLayout from 'shared-ui/components/split-layout/split-layout';
import type { NamedFC } from 'shared-ui/named-fc.types';

import { fillRegistrationForm } from 'functions/register/utils/registration-form-filler';

import { Register as Props } from './register.props';

const Register: NamedFC<Props> = ({
  endpoints,
  generalTerms,
  hiddenInputs = [],
  isDebugMode,
  language,
  layout,
  login,
  marketingConsent,
  memberForm,
  otherPayer,
  paidForDescription,
  paidForLabel,
  startDate,
  submit,
}) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isNewOtherPayer, setIsNewOtherPayer] = useState<boolean>(false);
  const [isTermsModalOpen, setTermsModalOpen] = useState<boolean>(false);
  const [isMarketingConsentModalOpen, setMarketingConsentModalOpen] =
    useState<boolean>(false);

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = event => {
    if (isSubmitting || !(event.target as HTMLFormElement).checkValidity()) {
      event.preventDefault();
      return;
    }
    setIsSubmitting(true);
  };

  return (
    <SplitLayout {...layout}>
      <div className="register">
        <form
          action={
            !otherPayer
              ? endpoints.existingOtherPayer
              : isNewOtherPayer
                ? endpoints.newOtherPayer
                : endpoints.memberPayer
          }
          method="POST"
          className="register__form"
          onSubmit={handleSubmit}
        >
          {hiddenInputs.map(hiddenInput => (
            <HiddenInput key={hiddenInput.name} {...hiddenInput} />
          ))}
          {isDebugMode ? (
            <div className="register__populate-fields-btn">
              <Button
                text="Populate fields"
                onClick={() => fillRegistrationForm(language)}
              />
            </div>
          ) : null}
          <Select name="startDate" {...startDate} />
          {otherPayer ? (
            <>
              <Checkbox
                theme={Checkbox.themes.blue}
                onChange={e => setIsNewOtherPayer(e.currentTarget.checked)}
                {...otherPayer.checkbox}
              />
              {isNewOtherPayer ? (
                <>
                  <div className="register__login">
                    <Text size={Text.sizes.large} theme={Text.themes.emphasis}>
                      {login.title}
                    </Text>
                    <Text>{login.description}</Text>
                    <div>
                      <LinkButton {...login.link} data-test-login />
                    </div>
                  </div>
                  <div
                    className={cn('register__form-inputs-container', {
                      'register__form-inputs-container--with-new-other-payer':
                        isNewOtherPayer,
                    })}
                  >
                    <div className="register__form-descriptions">
                      <Text
                        size={Text.sizes.large}
                        theme={Text.themes.emphasis}
                      >
                        {otherPayer.label}
                      </Text>
                      <Text>{otherPayer.description}</Text>
                    </div>
                    <FormInputs {...otherPayer.form} />
                  </div>
                </>
              ) : null}
            </>
          ) : null}
          <div
            className={cn('register__form-inputs-container', {
              'register__form-inputs-container--with-new-other-payer':
                isNewOtherPayer,
            })}
          >
            {isNewOtherPayer ? (
              <div className="register__form-descriptions">
                <Text size={Text.sizes.large} theme={Text.themes.emphasis}>
                  {paidForLabel}
                </Text>
                <Text>{paidForDescription}</Text>
              </div>
            ) : null}
            {memberForm ? <FormInputs {...memberForm} /> : null}
          </div>
          {generalTerms && generalTerms.terms ? (
            <div className="register__terms">
              <Checkbox
                theme={Checkbox.themes.blue}
                {...generalTerms.checkbox}
              />
              <div>
                <Button
                  {...generalTerms.button}
                  variant={Button.variants.tertiary}
                  size={Button.sizes.small}
                  onClick={() => setTermsModalOpen(true)}
                />
              </div>
              {isTermsModalOpen ? (
                <Modal
                  {...generalTerms.modal}
                  onClose={() => setTermsModalOpen(false)}
                >
                  <RichText {...generalTerms.terms} />
                </Modal>
              ) : null}
            </div>
          ) : null}
          {marketingConsent && marketingConsent.terms ? (
            <div className="register__terms">
              <Checkbox
                theme={Checkbox.themes.blue}
                {...marketingConsent.checkbox}
              />
              <div>
                <Button
                  {...marketingConsent.button}
                  variant={Button.variants.tertiary}
                  size={Button.sizes.small}
                  onClick={() => setMarketingConsentModalOpen(true)}
                />
              </div>
              {isMarketingConsentModalOpen ? (
                <Modal
                  {...marketingConsent.modal}
                  onClose={() => setMarketingConsentModalOpen(false)}
                >
                  <RichText {...marketingConsent.terms} />
                </Modal>
              ) : null}
            </div>
          ) : null}
          <div className="register__submit">
            <Button
              size={Button.sizes.large}
              type="submit"
              text={submit}
              disabled={isSubmitting ? true : undefined}
              theme={isSubmitting ? 'spinner' : undefined}
            />
          </div>
        </form>
      </div>
    </SplitLayout>
  );
};

Register.displayName = 'Register';

export default Register;
