import Check from '@sats-group/icons/16/check';
import Close from '@sats-group/icons/16/close';
import cn from 'classnames';
import React from 'react';

import SvgElixia from 'sats-ui-lib/react/logos/elixia-small';
import Sats from 'sats-ui-lib/react/logos/sats-small';
import Text from 'sats-ui-lib/react/text';

import ContentContainer from 'shared-ui/components/content-container/content-container';
import type { NamedFC } from 'shared-ui/named-fc.types';

import { PocChooseMembership as Props } from './poc-choose-membership.props';

const PocChooseMembership: NamedFC<Props> = ({
  language,
  memberships,
  memberTypes,
}) => {
  return (
    <div className="poc-choose-membership">
      <ContentContainer
        className="poc-choose-membership__content"
        theme={ContentContainer.themes.wide}
      >
        <div className="poc-choose-membership__logo">
          {language === 'fi' ? <SvgElixia /> : <Sats />}
        </div>
        <div className="poc-choose-membership__content poc-choose-membership__main">
          <section className="poc-choose-membership__section">
            <Text
              className="poc-choose-membership__section-title"
              theme={Text.themes.headline}
              size={Text.sizes.headline2}
            >
              Bli medlem på SATS!
            </Text>
            <div className="poc-choose-membership__member-types">
              {memberTypes.map(type => {
                const Element = type.selected ? 'div' : 'a';
                return (
                  <Element
                    className={cn('poc-choose-membership__member-type', {
                      'poc-choose-membership__member-type--selected':
                        type.selected,
                    })}
                    key={type.name}
                    href={type.href}
                  >
                    <Text
                      theme={
                        type.selected
                          ? Text.themes.emphasis
                          : Text.themes.normal
                      }
                      size={Text.sizes.large}
                    >
                      {type.name}
                    </Text>
                  </Element>
                );
              })}
            </div>
          </section>
          <section className="poc-choose-membership__section poc-choose-membership__select-membership">
            <div className="poc-choose-membership__memberships">
              {memberships.map(membership => (
                <div
                  className={cn('poc-choose-membership__membership', {
                    'poc-choose-membership__membership--highlighted':
                      membership.highlighted,
                  })}
                  key={membership.name}
                >
                  <div className="poc-choose-membership__membership-main-content">
                    {/* {membership.highlighted ? (
                      <div className="poc-choose-membership__membership-flag">
                        Mest populært!
                      </div>
                    ) : null} */}
                    <Text theme={Text.themes.headline} size={Text.sizes.large}>
                      {membership.name}
                    </Text>
                    <div className="poc-choose-membership__membership-list">
                      {membership.benefits.map(benefit => (
                        <div
                          className="poc-choose-membership__membership-list-item"
                          key={benefit}
                        >
                          <Check />
                          <Text size={Text.sizes.small}>{benefit}</Text>
                        </div>
                      ))}
                      {membership.drawbacks.map(drawback => (
                        <div
                          className="poc-choose-membership__membership-list-item"
                          key={drawback}
                        >
                          <Close />
                          <Text size={Text.sizes.small}>{drawback}</Text>
                        </div>
                      ))}
                    </div>
                    <Text
                      theme={Text.themes.headline}
                      size={Text.sizes.large}
                      italic
                    >
                      {membership.price}
                    </Text>
                  </div>
                  <a
                    className={cn('poc-choose-membership__link', {
                      'poc-choose-membership__link--highlighted':
                        membership.highlighted,
                    })}
                    href={membership.href}
                  >
                    <Text size={Text.sizes.large} theme={Text.themes.emphasis}>
                      Velg
                    </Text>
                  </a>
                </div>
              ))}
            </div>
          </section>
        </div>
      </ContentContainer>
    </div>
  );
};

PocChooseMembership.displayName = 'PocChooseMembership';

export default PocChooseMembership;
