import type { Document } from '@contentful/rich-text-types';

import { ObjectValues } from 'sats-ui-lib/react/types';

export const themes = {
  'small-paragraphs': 'small-paragraphs',
} as const;

export type RichText = {
  content?: Document;
  theme?: ObjectValues<typeof themes>;
};
