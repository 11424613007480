import React from 'react';

import Club from '../club/club';

import { Cluster as Props } from './cluster.props';

const Cluster: React.FC<Props> = ({ clubs }) => (
  <div className="cluster">
    {clubs.map(club => (
      <div key={club.id}>
        <Club {...club} />
      </div>
    ))}
  </div>
);

export default Cluster;
